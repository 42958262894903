import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'
import {apiUrl} from "@/variables"
import VueJwtDecode from 'vue-jwt-decode'
import countries from "i18n-iso-countries";

// register en and de as default
countries.registerLocale(require(`i18n-iso-countries/langs/en.json`));
countries.registerLocale(require(`i18n-iso-countries/langs/de.json`));

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        status: '',
        loginError: '',
        registerError: '',
        token: localStorage.getItem('token') || '',
        userRoles: [],
        countries: countries,
        supportedLangs: [],
        isoCountryList: []
    },
    mutations: {
        auth_success(state, token) {
            state.token = token
        },
        auth_error(state, error) {
            state.status = 'error'
            state.loginError = error
        },
        logout(state) {
            state.status = ''
            state.token = ''
            state.userRoles = []
        },
        commitCurrentUserRoles(state, userRoles) {
            state.userRoles = userRoles
        },
        commitRegisterCountries(state, isoCountryList) {
            state.isoCountryList = isoCountryList
        }
    },
    actions: {
        registerCountries({commit}) {
            // register all alpha2 codes
            let alpha2 = Object.keys(countries.getAlpha2Codes())

            // collection of supported countries with available english name
            let isoCountryList = []

            // fill isoCountryList with supported countries
            for (let it of alpha2) {
                let country = {
                    code: it.toLowerCase(),
                    deName: countries.getName(it.toUpperCase(), 'de'),
                    enName: countries.getName(it.toUpperCase(), 'en')
                }

                // if no enName found - country is not supported (enName is needed for user country saving)
                if (!country.enName) {
                    continue
                }

                // if no deName found - use enName as fallback (=> only eventually needed for future update compatibility)
                if (!country.deName) {
                    country.deName = country.enName
                }

                // add country to isoCountryList
                isoCountryList = [...isoCountryList, country]
                isoCountryList.sort((a, b) => (a.enName > b.enName) ? 1 : ((b.enName > a.enName) ? -1 : 0))
            }
            commit('commitRegisterCountries', isoCountryList)
        },
        setCurrentUserRoles({commit}) {
            const token = localStorage.getItem("token")
            const user = VueJwtDecode.decode(token)
            if (user && user.roles) {
                commit('commitCurrentUserRoles', user.roles)
            }
        },
        authStatus() {
            const headers = {Authorization: 'Bearer ' + localStorage.getItem("token") || null}

            axios.post(apiUrl + `/api/auth`, '', {
                headers: headers
            }).catch(() => {
                localStorage.removeItem('token')
                router.push({'path': '/'})
            })
        },
        login({commit}, payload) {
            return new Promise((resolve, reject) => {
                console.log(reject)

                const headers = {
                    'Content-Type': 'application/json'
                }

                axios.post(apiUrl + `/api/login`, payload, {
                    headers: headers
                }).then(response => {
                    if (response.code === 401) {
                        commit('auth_error', 'XXX')
                        resolve(response)
                    } else {
                        const token = response.data.token
                        localStorage.setItem("token", token)

                        commit('auth_success', token)
                        commit('auth_error', "")
                        resolve(response)
                        router.push('/dashboard')
                    }
                }).catch(error => {
                    commit('auth_error', error.response.data.message)
                })
            })
        },
        logout() {
            return new Promise(() => {
                router.push({path: '/'})
                localStorage.removeItem('token')
            })
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        loginError: state => state.loginError,
        registerError: state => state.registerError,
        authStatus: state => state.status
    }
})
