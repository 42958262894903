<template>
  <v-container class="pa-5 pb-16" fill-height fluid>

    <v-layout align-center justify-center>
      <v-flex md4 sm8 xs12>
        <v-img height="200" src="/cemo-logo.svg"></v-img>
        <v-card class="elevation-12">
          <v-card-text>
            {{ $i18n.t('mediadb.welcome.text.thanks_for_registration.0') }}<br><br>

            {{ $i18n.t('mediadb.welcome.text.thanks_for_registration.1') }}
          </v-card-text>
          <v-card-actions class="pt-5">
            <v-spacer></v-spacer>
            <v-btn block color="#ebead8" light @click="goToLogin()">{{
                $i18n.t('mediadb.welcome.button.back_to_login')
              }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import router from "@/router";

export default {
  name: 'Welcome',
  methods: {
    goToLogin() {
      // Zurück zur Login-Maske
      router.push('/')
    }
  }
}
</script>
