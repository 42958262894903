<template>
  <div>
    <a href="javascript:" @click="setLocalStorage('de')">
      <img alt="de-flag" class="mr-2 tm-lang-flag" src="/flags/countries/de.svg"/>
    </a>
    <a href="javascript:" @click="setLocalStorage('en')">
      <img alt="us-flag" class="tm-lang-flag" src="/flags/countries/uk.svg"/>
    </a>
  </div>
</template>

<script>

export default {
  data: () => ({
    langs: [
      {iso: 'de', label: 'Deutsch'},
      {iso: 'en', label: 'English'}
    ]
  }),
  methods: {
    setLocalStorage(newLang) {
      localStorage.setItem('lang', newLang);
      this.$i18n.locale = localStorage.getItem('lang');
    }
  }
};
</script>

<style lang="scss" scoped>
.tm-lang-flag {
  height: 1.5rem
}
</style>
