import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {apiUrl} from "@/variables";
import axios from "axios";

Vue.use(VueI18n);

function loadLocaleMessages() {
    const messages = {};
    const langUri = apiUrl;

    // let de = axios.get(langUri + '/locales/de.json');
    // let en = axios.get(langUri + '/locales/en.json');
    //
    // Promise.all([de, en]).then((values) => {
    //     messages['de'] = values[0].data
    //     messages['en'] = values[1].data
    // })

    axios.get(langUri + '/locales/de.json').then(response => {
        messages['de'] = response.data;
    });
    axios.get(langUri + '/locales/en.json').then(response => {
        messages['en'] = response.data;
    });

    return messages;
}

export default new VueI18n({
    locale: localStorage.getItem('lang') || process.env.VUE_APP_I18N_LOCALE || 'de',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
    messages: loadLocaleMessages(),
})