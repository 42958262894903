<template>
  <v-app style="background-color: #f1f1f1">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

import i18n from "@/i18n";

export default {
  beforeCreate() {
    // list available languages for each JSON file, to validate, that all languages are loaded
    let availableLanguages = ["de", "en"]

    // validate loading of languages
    let areAllLanguagesLoaded = setInterval(() => {
      const currentlyLoadedLanguages = Object.keys(i18n.messages).slice().sort();

      // true => all availableLanguages are loaded; false => missing availableLanguages
      let allLanguagesAreLoaded = availableLanguages.length === Object.keys(i18n.messages).length && availableLanguages.slice().sort().every(function (value, index) {
        return value === currentlyLoadedLanguages[index];
      });

      if (allLanguagesAreLoaded) {
        // Re-Init language; needs to be asynchronous to work!
        let lang = localStorage.getItem('lang') || process.env.VUE_APP_I18N_LOCALE || 'de'
        i18n.locale = '' // reset language to trigger following language change
        setTimeout(() => {
          i18n.locale = lang
        })

        clearInterval(areAllLanguagesLoaded)
      }
    }, 1);
  },
}
</script>

<style>
h3 {
  margin-bottom: 10px;
}

.hint {
  color: gray;
}
</style>
