<template>
  <div class="text-center">
    <v-dialog
        v-model="dialogImages"
        width="70%"
    >
      <template v-slot:activator="{ on, attrs }">
        <!-- show only if user has download permissions -->
        <template
            v-if="$store.state.userRoles.canDownloadProductImages && images && Object.keys(images).length && Object.keys(images).length >= 1">
          <v-btn
              block
              color="#ff9800"
              dark
              v-bind="attrs"
              v-on="on"
          >
            {{ $i18n.t('mediadb.images_lightbox.button.product_images') }}
          </v-btn>
        </template>

        <!-- show only if user has download permissions -->
        <template
            v-if="$store.state.userRoles.canDownloadProductDocuments && documents && Object.keys(documents).length && Object.keys(documents).length >= 1">
          <v-btn block
                 class="mt-2"
                 color="#ff9800"
                 dark
                 @click.stop="dialogDocuments = true"
          >
            {{ $i18n.t('mediadb.images_lightbox.button.product_documents') }}
          </v-btn>
        </template>
      </template>

      <v-card>

        <v-card-title>{{ name }}</v-card-title>

        <v-card-text>
          <ImagesTable :images="images"></ImagesTable>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="secondary"
              @click="dialogImages = false"
          >
            {{ $i18n.t('mediadb.images_lightbox.button.dialog_close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogDocuments"
        width="70%"
    >
      <v-card>

        <v-card-title>{{ name }}</v-card-title>

        <v-card-text>
          <DocumentsTable :documents="documents"></DocumentsTable>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="secondary"
              @click="dialogDocuments = false"
          >
            {{ $i18n.t('mediadb.images_lightbox.button.dialog_close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import DocumentsTable from "@/components/snippets/DocumentsTable";
import ImagesTable from "@/components/snippets/ImagesTable";

export default {
  components: {
    ImagesTable,
    DocumentsTable
  },
  props: {
    name: String,
    images: Array,
    documents: Array
  },
  data() {
    return {
      dialogImages: false,
      dialogDocuments: false
    }
  }
}
</script>
