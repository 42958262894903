<template>
  <div>
    <language-flags class="position-absolute end-0 mr-3 mt-3"/>

    <template>
      <b-container>
        <b-row>
          <b-col class="col-12 col-sm-3">
            <v-img height="100" max-height="100" max-width="400" min-height="100" src="/cemo-logo.svg"
                   style="left: -0.5rem;"></v-img>
          </b-col>
          <b-col align="right" align-self="center" class="col-12 col-sm-9">
            <!-- show only if user has download permissions -->
            <template
                v-if="$store.state.userRoles.canDownloadProductImages || $store.state.userRoles.canDownloadProductDocuments">
              <v-dialog
                  v-model="dialog"
                  width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      large
                      v-bind="attrs"
                      v-on="on"
                  >
                    <span>{{ $i18n.t('mediadb.home.button.multi_download') }}</span>
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    {{ $i18n.t('mediadb.home.text.multi_download_title') }}
                  </v-card-title>

                  <v-card-text>
                    <b-row>
                      <b-col class="col-12 pt-10 pb-0">
                        <v-alert
                            outlined
                            text
                            type="info"
                        >{{ $i18n.t('mediadb.home.text.multi_download_description') }}
                        </v-alert>
                      </b-col>
                    </b-row>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <div>
                      <b-col class="col-12 pt-0 pb-4">
                        <v-btn class="mr-2" large @click="handleFileImport">
                          <span>{{ $i18n.t('mediadb.home.button.csv_upload') }}</span>
                          <v-icon>mdi-upload</v-icon>
                        </v-btn>
                        <v-btn class="mr-2" large @click="handleDemoCsvDownload">
                          <span>{{ $i18n.t('mediadb.home.button.csv_demo') }}</span>
                          <v-icon>mdi-download</v-icon>
                        </v-btn>

                        <v-file-input ref="uploader" v-model="selectedFile" accept="text/csv" class="d-none"
                                      prepend-icon="mdi-upload"
                                      @change="onFileChanged"></v-file-input>
                      </b-col>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <v-btn class="ml-5" large @click="logout">
              <span>{{ $i18n.t('mediadb.home.button.sign_out') }}</span>
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </b-col>
        </b-row>

        <ais-instant-search :index-name="liveIndexName" :search-client="searchClient">
          <b-row>
            <b-col class="py-5" cols="12">
              <ais-search-box
                  :class-names="{
                    'ais-SearchBox': 'text-center',
                    'ais-SearchBox-input': 'w-50',
                  }"
                  :placeholder="`${$i18n.t('mediadb.home.input.search_article')}`"
              />
            </b-col>
            <b-col cols="12">
              <ais-hits>
                <template v-slot:item="{ item }">
                  <b-card
                      :title="item.description"
                      class="mb-2 border-0"
                      style="max-width: 20rem"
                      tag="article"
                  >
                    <v-img
                        v-if="item && item.images && Object.keys(item.images).length && Object.keys(item.images).length >= 1 && item.images[0]"
                        :src="liveUrl + item.images[0].thumbnail"
                    />
                    <v-img v-else src="/no_image_found.jpg"/>

                    <b-card-text class="text-center pt-10">
                      <strong>{{ $i18n.t('mediadb.home.text.order_no') }}</strong> {{ item.orderno }}
                    </b-card-text>
                    <images-lightbox :documents="item.documents" :images="item.images"
                                     :name="item.description"></images-lightbox>
                    <v-btn
                        block
                        class="mt-2"
                        color="#ff9800"
                        dark
                        target="_blank"
                        :href="liveUrl + '/pdf/single/' + item.orderno + '?lang=' + $i18n.locale"
                    >{{ $i18n.t('mediadb.data_sheet.product') }}</v-btn>
                  </b-card>
                </template>
              </ais-hits>
            </b-col>
          </b-row>

          <ais-pagination
              :padding="3"
              :show-first="true"
              :show-last="true"
              :show-next="true"
              :show-previous="true"
              class="text-center pt-12"
          />
        </ais-instant-search>
      </b-container>
    </template>

    <v-snackbar
        v-model="snackbarCsvError"
        :value="snackbarCsvErrorValue"
        color="error"
        text
        timeout="5000"
        top
    >
      {{ snackbarCsvErrorValue }}.
    </v-snackbar>
  </div>
</template>

<script>

import algoliasearch from "algoliasearch/lite"
import "instantsearch.css/themes/satellite.css"
import ImagesLightbox from "./snippets/ImagesLightbox"
import {apiUrl, liveIndexName, liveUrl} from "@/variables"
import axios from "axios"
import LanguageFlags from "@/components/Navigation/LanguageFlags";

export default {
  data() {
    return {
      searchClient: algoliasearch(
          "S621BFXP21",
          "58b0420b9015a38a54b905c1edaa92e3"
      ),
      liveUrl: liveUrl,
      liveIndexName: liveIndexName,
      selectedFile: null,
      loading: false,
      snackbarCsvError: false,
      snackbarCsvErrorValue: '',
      dialogCsvUpload: false,
      dialog: false
    }
  },
  methods: {
    apiUrl() {
      return apiUrl
    },
    logout() {
      this.$store.dispatch('logout')
    },
    handleFileImport() {
      // Trigger click on the FileInput
      this.$refs.uploader.$refs.input.click()
    },
    handleDemoCsvDownload() {
      // string with escaped doublequotes (") to ensure no breaking singlequotes in csv (')
      let csvContent = "data:text/csv;charset=utf-8,"
          + "\"1029\",\"" + this.$i18n.t('mediadb.csv.text.demo_description.0') + "\"\r\n"
          + "\"1072\",\"" + this.$i18n.t('mediadb.csv.text.demo_description.1') + "\"\r\n"
          + "\"10505\",\"" + this.$i18n.t('mediadb.csv.text.demo_description.2') + "\"\r\n"
          + "\"1078\",\"" + this.$i18n.t('mediadb.csv.text.demo_description.3') + "\""
      ;

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "cemo-demo-upload.csv");
      document.body.appendChild(link); // Required for Firefox

      link.click();
    },
    onFileChanged(file) {
      // save file temporary
      this.selectedFile = file
      // trigger download and file handling
      this.csv2zipDownload()
    },
    pollAuth() {
      this.$store.dispatch('authStatus')
    },
    csv2zipDownload() {
      if (this.selectedFile) {
        const formData = new FormData()
        formData.append('csvFile', this.selectedFile)

        this.loading = true
        const headers = {
          Authorization: 'Bearer ' + localStorage.getItem("token") || null
        }
        axios.post(liveUrl + "/api/csv2zip", formData, {responseType: 'blob', headers: headers})
            .then(response => {
              this.loading = false
              this.selectedFile = null
              const blob = new Blob([response.data], {type: 'application/zip'})
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = "cemo-csv-download.zip"
              link.click()
              URL.revokeObjectURL(link.href)
            })
            .catch(error => {
              let status = error.response.status

              if (status === 401) {
                this.$store.dispatch('logout')
              }

              if (status === 403) {
                this.snackbarCsvErrorValue = `${this.$i18n.t('mediadb.home.error.no_permission')}`
                this.snackbarCsvError = true
              }

              if (status === 413) {
                this.snackbarCsvErrorValue = `${this.$i18n.t('mediadb.home.error.limit_exceeded')}`
                this.snackbarCsvError = true
              }

              if (status === 500) {
                this.snackbarCsvErrorValue = `${this.$i18n.t('mediadb.home.error.unknown')}`
                this.snackbarCsvError = true
                this.error = true
                this.loading = false
                this.selectedFile = null
              }
            })
      }
    }
  },
  created() {
    this.$store.dispatch('setCurrentUserRoles')
  },
  mounted() {
    this.pollAuth()
    this.polling = setInterval(() => {
      this.pollAuth()
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.polling)
  },
  components: {
    ImagesLightbox,
    LanguageFlags
  }
}

</script>


<style>
.ais-Hits-list {
  display: flex;
  flex-wrap: wrap;
}

.ais-Hits-item {
  width: 33%;
  border: none;
}

.ais-Pagination-list {
  display: inline-block;
}

@media (max-width: 880px) {
  .ais-Hits-item {
    width: 100%;
  }

  .ais-Hits-item > article {
    margin: auto;
  }
}

/* overwrites the list positioning for better space consuption for responsive design */
.v-application ul, .v-application ol {
  padding-left: 2px;
}

/* margin correction on bigger screens to flush lines with search box */
@media (min-width: 881px) {
  .v-application ul, .v-application ol {
    margin-right: -1%;
  }
}

/* centers any ais hits items in their cards */
.ais-Hits-item, .ais-InfiniteHits-item {
  justify-content: center;
}
</style>
