<template>
  <v-container class="pa-5 pb-16" fill-height fluid>
    <language-flags class="position-absolute end-0 top-0 mr-3 mt-3"/>
    <v-layout align-center justify-center>
      <v-flex md4 sm8 xs12>
        <v-img height="200" src="/cemo-logo.svg"></v-img>
        <v-card class="elevation-12 pb-4">
          <v-alert v-if="loginError" type="error">{{ $i18n.t('mediadb.login.submit.error') }}</v-alert>
          <v-card-text class="pb-0">
            <v-form>
              <v-text-field
                  v-model="username"
                  :label="$i18n.t('mediadb.login.input.username')"
                  name="login"
                  prepend-icon="mdi-account-box"
                  type="text"
              ></v-text-field>
              <v-text-field
                  id="password"
                  v-model="password"
                  :label="$i18n.t('mediadb.login.input.password')"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  @keyup.enter="login()"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="pt-5">
            <v-spacer></v-spacer>
            <v-btn block color="#ebead8" light @click="login()">{{
                $i18n.t('mediadb.login.button.sign_in')
              }}
            </v-btn>
          </v-card-actions>
          <v-card-actions class="pt-5">
            <v-btn block color="#ebead8" light to="/register">{{ $i18n.t('mediadb.login.button.register') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>


</template>

<script>
import LanguageFlags from "@/components/Navigation/LanguageFlags";

export default {
  name: 'Login',
  data: function () {
    return {
      username: '',
      password: '',
    }
  },
  computed: {
    loginError: function () {
      return this.$store.getters.loginError
    }
  },
  methods: {
    login() {
      this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      })
    }
  },
  components: {
    LanguageFlags
  }
}
</script>
