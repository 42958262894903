import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'

import VueApollo from 'vue-apollo'
import {ApolloClient} from 'apollo-client'
import {ApolloLink} from "apollo-link"
import {createHttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'

import InstantSearch from 'vue-instantsearch';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import {apiUrl} from './variables'
import i18n from "@/i18n";

Vue.use(Vuex)
Vue.use(InstantSearch)
Vue.use(BootstrapVue, IconsPlugin)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

// Cache implementation
const cache = new InMemoryCache()

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: apiUrl + '/pimcore-graphql-webservices/media'
})

const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            "X-API-Key": "4a361e9b63e50c611bb91dda2ebb8b96",
            Authorization: 'Bearer ' + localStorage.getItem("token") || null
        }
    });
    return forward(operation);
});

const linkWithAuth = middlewareLink.concat(httpLink);

const userClient = new ApolloClient({
    link: linkWithAuth,
    cache,
    defaultOptions: {
        fetchPolicy: 'no-cache'
    }
})

const apolloProvider = new VueApollo({
    defaultClient: userClient,
    clients: {
        userClient
    }
})

Vue.use(VueApollo)

new Vue({
    vuetify,
    router,
    store,
    apolloProvider,
    i18n,
    render: h => h(App)
}).$mount('#app')