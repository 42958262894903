<template>
  <div>
    <v-row no-gutters>
      <v-col class="mb-1">
        <v-btn :loading="loading" class="mb-2 mr-5" color="primary" @click="downloadAll">
          {{ $i18n.t('mediadb.images_table.button.download_selected') }}
          ({{ selected.length }})
        </v-btn>
        <v-switch v-model="imagesAsJpeg"
                  :label="$i18n.t('mediadb.images_table.button.download_selected_as_jpeg')"
                  class="d-inline-flex mt-0"
        ></v-switch>
      </v-col>
    </v-row>
    <v-alert v-if="error" dismissible type="error">{{ $i18n.t('mediadb.images_table.download_error') }}</v-alert>
    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="images"
        class="elevation-1"
        height="400"
        item-key="originalImage"
        show-select
    >
      <template v-slot:item.thumbnail="{ item }">
        <v-img :src="liveUrl+item.thumbnail" width="100"></v-img>
      </template>

      <template v-slot:item.fileSize="{ item }">
        <v-card-text>{{ (item.fileSize / 1000000).toFixed(2) }} MB</v-card-text>
      </template>
    </v-data-table>
  </div>

</template>

<script>

import {liveUrl} from '@/variables';
import axios from "axios";

export default {
  props: {
    name: String,
    images: Array
  },
  data() {
    return {
      loading: false,
      error: false,
      liveUrl: liveUrl,
      imagesAsJpeg: false,
      selected: [],
      headers: [
        {
          text: `${this.$i18n.t('mediadb.images_table.header.product_images')}`,
          value: 'thumbnail'
        },
        {
          text: `${this.$i18n.t('mediadb.images_table.header.filesize')}`,
          value: 'fileSize'
        }
      ]
    }
  },
  methods: {
    downloadAll() {
      if (this.selected.length > 0) {
        this.loading = true
        const headers = {
          Authorization: 'Bearer ' + localStorage.getItem("token") || null
        }
        axios.post(liveUrl + "/api/zip", {
          'images': this.selected,
          imageFormat: this.imagesAsJpeg ? 'jpeg' : 'tiff'
        }, {responseType: 'blob', headers: headers})
            .then(response => {
              this.loading = false
              this.selected = []
              const blob = new Blob([response.data], {type: 'application/zip'})
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = `${this.$i18n.t('mediadb.images_table.download.filename')}`
              link.click()
              URL.revokeObjectURL(link.href)
            })
            .catch(error => {
              let status = error.response.status

              if (status === 401) {
                this.$store.dispatch('logout')
              }

              if (status === 403 || status === 500) {
                this.error = true
                this.loading = false
                this.selected = []
              }
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
