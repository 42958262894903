import gql from "graphql-tag"

export const createUser = gql`mutation createUser($key: String!, $user: UpdateUserInput!) {
    createUser(
      key: $key
      path: "/Benutzer"
      input: $user
    ) {
      success
      output {
        id
        key
        published
      }
    }
  }
`
