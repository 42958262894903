<template>
  <v-container class="pa-5 pb-16" fill-height fluid>
    <v-layout align-center column justify-center>
      <v-img height="120" max-height="120" src="/cemo-logo.svg" width="320"></v-img>
      <v-card class="elevation-12">
        <v-alert v-if="registerError" type="error">{{ $i18n.t('mediadb.register.submit.error') }}</v-alert>
        <v-card-text class="pb-0" style="max-width: 680px">
          <v-form>
            <v-container class="pb-0">
              <v-row>
                <v-col class="col-12 col-sm-6 py-0">
                  <v-text-field
                      id="customer-id"
                      ref="customerId"
                      v-model="user.customerId"
                      :label="$i18n.t('mediadb.register.input.customer_id')"
                      name="customer-id"
                      type="text"
                      @focus="setSubmitButton(true)"
                  ></v-text-field>
                </v-col>
                <v-col class="col-12 col-sm-6 py-0">
                  <v-text-field
                      id="company"
                      ref="company"
                      v-model="user.company"
                      :label="$i18n.t('mediadb.register.input.company') + ' *'"
                      :rules="[rules.requiredInput]"
                      name="company"
                      required
                      type="text"
                      @focus="setSubmitButton(true)"
                  ></v-text-field>
                </v-col>
                <v-col class="col-12 col-sm-6 py-0">
                  <v-text-field
                      id="forename"
                      ref="forename"
                      v-model="user.forename"
                      :label="$i18n.t('mediadb.register.input.forename') + ' *'"
                      :rules="[rules.requiredInput]"
                      name="forename"
                      required
                      type="text"
                      @focus="setSubmitButton(true)"
                  ></v-text-field>
                </v-col>
                <v-col class="col-12 col-sm-6 py-0">
                  <v-text-field
                      id="surname"
                      ref="surname"
                      v-model="user.surname"
                      :label="$i18n.t('mediadb.register.input.surname') + ' *'"
                      :rules="[rules.requiredInput]"
                      name="surname"
                      required
                      type="text"
                      @focus="setSubmitButton(true)"
                  ></v-text-field>
                </v-col>
                <v-col class="col-9 col-sm-9 py-0">
                  <v-text-field
                      id="street"
                      ref="street"
                      v-model="user.street"
                      :label="$i18n.t('mediadb.register.input.street') + ' *'"
                      :rules="[rules.requiredInput]"
                      name="street"
                      required
                      type="text"
                      @focus="setSubmitButton(true)"
                  ></v-text-field>
                </v-col>
                <v-col class="col-3 col-sm-3 py-0">
                  <v-text-field
                      id="street-no"
                      ref="streetNo"
                      v-model="user.streetNo"
                      :label="$i18n.t('mediadb.register.input.street_no') + ' *'"
                      :rules="[rules.requiredInput]"
                      name="street-no"
                      required
                      type="text"
                      @focus="setSubmitButton(true)"
                  ></v-text-field>
                </v-col>
                <v-col class="col-5 col-sm-3 py-0">
                  <v-text-field
                      id="zip"
                      ref="zip"
                      v-model="user.zip"
                      :label="$i18n.t('mediadb.register.input.zip') + ' *'"
                      :rules="[rules.requiredInput, rules.isValidZip]"
                      name="zip"
                      required
                      type="text"
                      @focus="setSubmitButton(true)"
                  ></v-text-field>
                </v-col>
                <v-col class="col-7 col-sm-9 py-0">
                  <v-text-field
                      id="city"
                      ref="city"
                      v-model="user.city"
                      :label="$i18n.t('mediadb.register.input.city') + ' *'"
                      :rules="[rules.requiredInput]"
                      name="city"
                      required
                      type="text"
                      @focus="setSubmitButton(true)"
                  ></v-text-field>
                </v-col>
                <v-col class="col-12 col-sm-6 py-0">
                  <v-autocomplete
                      id="country"
                      ref="country"
                      v-model="user.country"
                      :filter="customFilter"
                      :items="$store.state.isoCountryList"
                      :label="$i18n.t('mediadb.register.input.country') + ' *'"
                      :rules="[rules.requiredInput]"
                      item-text="deName"
                      item-value="enName"
                      name="country"
                      required
                      @change="validateCountry"
                      @focus="setSubmitButton(true)"
                  >
                    <template v-slot:selection="slotProps">
                      {{ slotProps.item.enName }} ({{ slotProps.item.deName }})
                    </template>
                    <template v-slot:item="slotProps">
                      {{ slotProps.item.enName }} ({{ slotProps.item.deName }})
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="col-12 col-sm-6 py-0">
                  <v-text-field
                      id="username"
                      ref="username"
                      v-model="user.username"
                      :label="$i18n.t('mediadb.register.input.username') + ' *'"
                      :rules="[rules.requiredInput, rules.isValidEmail]"
                      name="username"
                      required
                      type="text"
                      @focus="setSubmitButton(true)"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-12 py-0">
                  <v-checkbox
                      id="license-agreement"
                      ref="licenseAgreement"
                      v-model="user.licenseAgreement"
                      :input-value="user.licenseAgreement"
                      :rules="[rules.requiredCheckbox]"
                      name="license-agreement"
                      required
                      @click="setSubmitButton(true)"
                  >
                    <template v-slot:label>
                      <div>
                        {{ $i18n.t('mediadb.register.text.license_agreement.0') }}
                        <a :href="liveUrl + '/licenseAgreement'" target="_blank"
                           @click.stop>{{ $i18n.t('mediadb.register.text.license_agreement.1') }}</a> *
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-5">
          <v-spacer></v-spacer>
          <v-btn :disabled="!submitButtonEnabled" block color="#ebead8" light @click="submit">
            {{ $i18n.t('mediadb.register.button.submit') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions class="pt-5">
          <v-btn block color="#ebead8" light to="/">{{ $i18n.t('mediadb.register.button.back') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>

    <v-snackbar
        v-model="snackbarCreateUserError"
        :value="snackbarCreateUserErrorValue"
        color="error"
        text
    >
      {{ snackbarCreateUserErrorValue }}.
    </v-snackbar>
  </v-container>
</template>

<script>
import {createUser} from '@/graphql/mutations'
import router from '@/router'
import {liveUrl} from '@/variables';

export default {
  name: 'Register',
  data: function () {
    return {
      liveUrl: liveUrl,
      snackbarCreateUserSuccess: false,
      snackbarCreateUserSuccessValue: '',
      snackbarCreateUserError: false,
      snackbarCreateUserErrorValue: '',
      submitButtonEnabled: true,
      validateCountry: () => {
        // revalidate zip after country changed
        this.$refs['zip'].validate(true)
      },
      validateZip: (value) => {
        // if country is filled
        if (this.form.country) {
          // germany needs 5 chars of digits
          if (this.form.country === 'Germany') {
            return /^\d{5}$/.test(value)
          }
        }
        // everything else that's not specified explicitly for validation is true without validating
        return true
      },
      validateEmail: value => /.+@.+\..+/.test(value),
      rules: {
        requiredInput: value => !!value || this.$i18n.t('mediadb.register.rules.please_fill'),
        requiredCheckbox: value => !!value || this.$i18n.t('mediadb.register.rules.please_confirm'),
        isValidZip: value => this.validateZip(value) || this.$i18n.t('mediadb.register.rules.5_digits'),
        isValidEmail: value => this.validateEmail(value) || this.$i18n.t('mediadb.register.rules.invalid_email')
      },
      user: {
        customerId: '',
        forename: '',
        surname: '',
        company: '',
        street: '',
        streetNo: '',
        zip: '',
        city: '',
        country: '',
        username: '',
        licenseAgreement: false
      }
    }
  },
  mounted() {
    // register countries for country select
    this.$store.dispatch('registerCountries').finally(() => {
      // default selected country => 'Germany'
      this.user.country = this.$store.state.isoCountryList.find(it => it.code === 'de')?.enName
    })
  },
  computed: {
    registerError: function () {
      return this.$store.getters.registerError
    },
    form() {
      return {
        customerId: this.user.customerId,
        forename: this.user.forename,
        surname: this.user.surname,
        company: this.user.company,
        street: this.user.street,
        streetNo: this.user.streetNo,
        zip: this.user.zip,
        city: this.user.city,
        country: this.user.country,
        username: this.user.username,
        licenseAgreement: this.user.licenseAgreement
      }
    }
  },
  methods: {
    customFilter(item, queryText) {
      let deName = item.deName.toLowerCase()
      let enName = item.enName.toLowerCase()
      let searchText = queryText.toLowerCase()
      return deName.indexOf(searchText) > -1 || enName.indexOf(searchText) > -1
    },
    // Submit-Button enablen/disablen
    setSubmitButton(bool) {
      this.submitButtonEnabled = bool
    },
    // Prüfen ob alle Formular-Pflichtfelder ausgefüllt sind
    isFormValid() {
      this.setSubmitButton(true)

      // special validation
      if (
          !this.validateZip(this.form.zip) ||
          !this.validateEmail(this.form.username)
      ) {
        this.setSubmitButton(false)
      }

      Object.keys(this.form).forEach(it => {
        // Optionale Felder müssen nicht gefüllt sein
        let optionalFieldList = ['customerId']

        // Submit Button deaktivieren, wenn ein Pflichtfeld nicht gefüllt ist
        if (!this.form[it] && !optionalFieldList.includes(it)) {
          this.setSubmitButton(false)
        }

        this.$refs[it].validate(true)
      })

      return this.submitButtonEnabled
    },
    // Formular abschicken
    submit() {
      // Wenn das Formular invalide ist, submit abbrechen
      if (!this.isFormValid()) {
        return
      }

      // Auch bei validem Formular, mehrmaliges submitten verhindern
      this.submitButtonEnabled = false

      // User registrieren
      this.$apollo
          .query({
            query: createUser,
            variables: {
              key: `${Math.floor(Math.random() * 999999) + 1}`,
              user: this.user
            },
            fetchPolicy: 'no-cache'
          })
          .then(() => {
            router.push('/welcome')
          })
          .catch(() => {
            this.snackbarCreateUserErrorValue = this.$i18n.t('mediadb.register.error.email_already_taken')
            this.snackbarCreateUserError = true
          })
    }
  }
}
</script>
