import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home'
import Welcome from "@/components/Welcome"
import store from '../store'
import Register from "@/components/Register"
import Login from "@/components/Login";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: {
            render(c) {
                return c('router-view')
            },
        },
        children: [
            {
                path: '/',
                name: 'Login',
                component: Login,
                meta: {isOpen: true},
            },
            {
                path: '/welcome',
                name: 'Welcome',
                component: Welcome,
                meta: {isOpen: true},
            },
            {
                path: '/register',
                name: 'Register',
                component: Register,
                meta: {isOpen: true},
            },
            {
                path: '/dashboard',
                name: 'Home',
                component: Home,
            },
        ],
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.isOpen)) {
        next()
    } else {
        if (store.getters.isLoggedIn) {
            next()
        } else {
            next('/')
        }
    }
})

export default router
