<template>
  <div>
    <v-btn :loading="loading" class="mb-5" color="primary" @click="downloadAll()">
      {{ $i18n.t('mediadb.documents_table.button.download_selected') }}
      ({{ selected.length }})
    </v-btn>
    <v-alert v-if="error" dismissible type="error">{{ $i18n.t('mediadb.documents_table.download_error') }}</v-alert>
    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="documents"
        class="elevation-1"
        height="400"
        item-key="document"
        show-select
    >
      <template v-slot:item.document="{ item }">
        <div class="d-flex my-3">
          <a @click="getPdfPreview(item.document)">
            <pdf style="max-width: 100px; margin-bottom: 7px"
                 :src="liveUrl + item.document"></pdf>
          </a>
          <div class="ml-3">
            {{ item.filename }}
          </div>
        </div>
      </template>

      <template v-slot:item.fileSize="{ item }">
        <v-card-text>{{ (item.fileSize / 1000000).toFixed(2) }} MB</v-card-text>
      </template>
    </v-data-table>

    <!-- PDF preview dialog -->
    <v-dialog
        v-model="pdfPreview"
        width="50%"

    >
      <v-btn id="close-button" icon @click="pdfPreview = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card>
        <pdf :src="liveUrl + document"></pdf>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {liveUrl} from '@/variables';
import axios from "axios";
import pdf from "vue-pdf";


export default {
  components: {pdf},
  props: {
    name: String,
    documents: Array
  },
  data() {
    return {
      document: "",
      pdfPreview: false,
      loading: false,
      error: false,
      liveUrl: liveUrl,
      selected: [],
      headers: [
        {
          text: `${this.$i18n.t('mediadb.documents_table.header.product_document')}`,
          value: 'document'
        },
        {
          text: `${this.$i18n.t('mediadb.documents_table.header.filesize')}`,
          value: 'fileSize'
        }
      ]
    }
  },
  methods: {
    getPdfPreview(document) {
      this.document = document
      this.pdfPreview = true
    },
    downloadAll() {
      if (this.selected.length > 0) {
        this.loading = true
        const headers = {
          Authorization: 'Bearer ' + localStorage.getItem("token") || null
        }
        axios.post(liveUrl + "/api/zip", {'documents': this.selected}, {responseType: 'blob', headers: headers})
            .then(response => {
              this.loading = false
              this.selected = []
              const blob = new Blob([response.data], {type: 'application/zip'})
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = `${this.$i18n.t('mediadb.documents_table.download.filename')}`
              link.click()
              URL.revokeObjectURL(link.href)
            })
            .catch(error => {
              let status = error.response.status

              if (status === 401) {
                this.$store.dispatch('logout')
              }

              if (status === 403 || status === 500) {
                this.error = true
                this.loading = false
                this.selected = []
              }
            })
      }
    }
  }
}
</script>

<style scoped>
a {
  cursor: zoom-in;
}

#close-button {
  position: absolute;
  right: 20vw;
  font-size: 3rem !important;
}


#close-button i {
  font-size: 3rem !important;
  color: #ff9800;
}

</style>
